import { List, ListItem } from "@mui/material";

function simpleGroupBy<T extends Record<PropertyKey, any>>(
    arr: T[],
    key: keyof T
): any {
    return arr.reduce((accumulator, val) => {
        const groupedKey = val[key];
        if (!accumulator[groupedKey]) {
            accumulator[groupedKey] = [];
        }
        accumulator[groupedKey].push(val);
        return accumulator;
    }, {} as any);
}

function safelyParseJSON(json: any) {
    var parsed;
    try {
        parsed = JSON.parse(json);
    } catch (e) {}
    return parsed;
}

export const UnorderedList = (item: any, index: number) => {
    //We need to group connected pieces together
    let groupedElements = simpleGroupBy(item.data.text, "group");
    let groupedContent = [];

    for (const [key] of Object.entries(groupedElements)) {
        //No grouping, treat normally
        if (key === "null") {
            groupedContent.push(
                groupedElements[key].map((listItem: any, index1: any) => {
                    return (
                        <ListItem
                            sx={{
                                ...(listItem.style && { ...listItem.style }),
                            }}
                            key={`${index}-${index1}`}
                        >
                            {listItem.value}
                        </ListItem>
                    );
                })
            );
        } else {
            //There is grouping, combine elements per group
            groupedContent.push(

                <ListItem key={key}>
                    {groupedElements[key].map((listItem: any, index2: any) => {
                        return (
                            <span style={safelyParseJSON(listItem.style)} key={index2}>
                                {listItem.value}
                            </span>
                        );
                    })}
                </ListItem>
            );
        }
    }

    let styleOverride = item.data.style
        ? JSON.parse(item.data.style)
        : [];

    return (
        <List
            key={index}
            sx={{
                pl: 4,
                marginBottom: "8px",
                fontSize: "1em",
                paddingTop:"0px",
                paddingBottom:"0px",
                "& .MuiListItem-root": {
                    display: "list-item",
                    listStyleType: "disc",
                    padding: "0px 0px"
                },
                ...(styleOverride && {
                    ...styleOverride,
                }),
            }}
        >
            {groupedContent}
        </List>
    );
};

export const HorizontalList = (item: any, index: number) => {
    let styleOverride = item.data.style
        ? JSON.parse(item.data.style)
        : [];
    return (
        <List
            key={index}
            // component={Stack}
            // direction="row"
            sx={{
                display: "flex",
                flexDirection: "row",
                overflowX: "auto",
                marginTop: ".5rem",
                marginBottom: ".5rem",
                paddingTop:"0px",
                paddingBottom:"0px",
                color: "#1e22aa", 
                fontSize: ".715em", 
                fontWeight: 700,
                "& .MuiListItem-root": {
                    display: "list-item",
                    listStyleType: "none",
                },
                ...(styleOverride && {
                    ...styleOverride,
                }),
            }}
        >
            {item.data.text.map((listItem: any, index: any) => {
                let styleOverride = listItem.style ? JSON.parse(listItem.style) : [];
                return <ListItem key={index} sx={{...(styleOverride && {...styleOverride}), textAlign:"center"}}>{listItem.value}</ListItem>;
            })}
        </List>
    );
};
